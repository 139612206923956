// Styles file for `qualifiedsolarsurvey`.

// Styles written here will override the corresponding component
// This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';

// Theming
const DEFAULT_COLORS = {
  questionTitle: '#000',
  text: '#000',
  headline: '#000',
  link: '#333',
  choiceInput: '#EDEDED',
  choiceInputHover: '#ccc',
  submitButtonBackground: '#00BF5F',
  submitButtonText: '#fff',
  headerBackground: '#fff',
  mainBackground: '',
  surveyBackground: '',
  footerBackground: '',
  progressBarBackground: '#ededed',
  progressBarFill: '#00BF5F',
  sliderFill: '#E9E9E9',
};

const DEFAULT_FONT = {
  questionTitle: '16px',
  text: '16px',
  headline: '24px',
  submitButton: '24px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: 'auto',
  headerMobileHeight: '',
  submitButtonWidth: '150px',
  inputFieldHeight: '40px',
  inputFieldWidth: '100%',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${DEFAULT_SIZE.headerHeight}; /* Don't forget to change the min-height calculation for SurveyBackground */

  width: 100vw;
  padding: 20px 0;
  margin: auto;
  background: ${DEFAULT_COLORS.headerBackground};
`;

const HeaderImage = css`
  height: auto;
  width: calc(100% - 30px);
  max-width: 350px;
  margin-bottom: 0;
  &.fourHundred {
    max-width: 400px;
  }
  .mWidth400 & {
    max-width: 400px;
  }
  .mWidth200 & {
    max-width: 200px;
  }
`;

// Only used when sites background color is needed
// specificy "background-color" because of the blurred-rates image.
const AppWrapper = css`
  background-color: ${DEFAULT_COLORS.mainBackground} !important;
`;

// Main Background
const SurveyBackground = css`
  min-height: calc(100vh - 92px);
  max-width: 960px;
  width: 100vw;
  margin: auto;
  background-color: ${DEFAULT_COLORS.surveyBackground};
  @media (max-width: 801px) {
    min-height: 760px;
  }
`;

const SurveyContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: 20px auto 0;
  padding: 30px 10px;
  @media (max-width: 801px) {
    padding: 10px;
    margin-top: 0;
  }

  div {
    &#alternate-container {
      /* Used if you need to overwrite the width of the hidden container */
      /* width: 100%; */
    }
  }
`;

const QuestionNumber = css`
  font-size: 16px;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 20px);
  background: #ededed;
  padding: 5px;
  border-bottom: 1px solid #787878;
`;

const FormContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0;
  width: 100%;
  max-width: 700px;
  padding: 40px 10px 20px;
  border: 1px solid #787878;
  margin: 0 auto;
`;

const GlobalSealImg = css`
  margin: 20px;
  max-width: 110px;
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 40px;
  margin: 8px 0;
  transition: background-color 230ms ease;
  width: 100%;
  height: ${DEFAULT_SIZE.inputFieldHeight};
  background: url(${images.Unchecked}) 20px 50%/15px no-repeat
    ${DEFAULT_COLORS.choiceInput};
  color: ${DEFAULT_COLORS.text};
  cursor: pointer;
  &[for='Mobile Home'] {
    display: none;
  }
  &:active {
    background: url(${images.Checked}) 20px 50%/15px no-repeat
      ${DEFAULT_COLORS.choiceInputHover};
  }

  &:hover {
    background: url(${images.Checked}) 20px 50%/15px no-repeat
      ${DEFAULT_COLORS.choiceInputHover};
    @media (max-width: 801px) {
      background: url(${images.Unchecked}) 20px 50%/15px no-repeat
        ${DEFAULT_COLORS.choiceInput};
    }
  }

  .input-ElectricCompany & {
    background: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    &:hover {
      background: #acdeb5;
    }
  }
  .input-ElectricBillButtons & {
    background: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    &:hover {
      background: #b5b2b2;
      @media (max-width: 801px) {
        background: url(${images.Unchecked}) 20px 50%/15px no-repeat
          ${DEFAULT_COLORS.choiceInput};
      }
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  .altShade & {
    &.input-Shade {
      width: 150px;
      max-width: 50%;
      @media (max-width: 801px) {
        margin-bottom: 10px;
      }
    }
  }

  &.input-ElectricCompany {
    max-width: 32%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0;

    > input:checked + label {
      background: #acdeb5;
      &:hover {
        background: #acdeb5;
      }
    }
    @media (max-width: 801px) {
      max-width: 100%;
    }
  }
  &.input-ElectricBillButtons {
    max-width: 32%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;

    > input:checked + label {
      background: #b5b2b2;
      &:hover {
        background: #b5b2b2;
      }
    }
    @media (max-width: 801px) {
      max-width: 100%;
      > input:checked + label {
        background: url(${images.Checked}) 20px 50%/15px no-repeat #b5b2b2;
        &:hover {
          background: url(${images.Checked}) 20px 50%/15px no-repeat #b5b2b2;
        }
      }
      > input + label {
        background: url(${images.Unchecked}) 20px 50%/15px no-repeat #e5e5e5;
      }
    }
  }

  > input:checked + label {
    background: url(${images.Checked}) 20px 50%/15px no-repeat
      ${DEFAULT_COLORS.choiceInput};
    &:hover {
      background: url(${images.Checked}) 20px 50%/15px no-repeat
        ${DEFAULT_COLORS.choiceInputHover};
    }
  }
  .altShade & {
    > input[id='No Shade'] + label,
    > input[id='A Little Shade'] + label,
    > input[id='A Lot of Shade'] + label,
    > input[id='Uncertain'] + label {
      width: 100%;
      height: 150px;
      margin: 2px 0;
      padding: 0 0 clamp(5px, 1vh, 20px);
      justify-content: center;
      align-items: flex-end;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background: url(${images.Unselected}) 0 50%/20px no-repeat;
      }
    }
    > input[id='No Shade']:checked + label,
    > input[id='A Little Shade']:checked + label,
    > input[id='A Lot of Shade']:checked + label,
    > input[id='Uncertain']:checked + label {
      border: 3px solid #00bf5f;
    }
    > input[id='No Shade'] + label {
      background: url(${images.NoShade}) 50% 50%/110px no-repeat;
      :hover {
        background: url(${images.NoShade}) 50% 50%/110px no-repeat #dedede;
        @media (max-width: 801px) {
          background-color: transparent;
        }
      }
    }
    > input[id='A Little Shade'] + label {
      background: url(${images.LittleShade}) 50% 50%/110px no-repeat;
      :hover {
        background: url(${images.LittleShade}) 50% 50%/110px no-repeat #dedede;
        @media (max-width: 801px) {
          background-color: transparent;
        }
      }
    }
    > input[id='A Lot of Shade'] + label {
      background: url(${images.LotShade}) 50% 50%/110px no-repeat;
      :hover {
        background: url(${images.LotShade}) 50% 50%/110px no-repeat #dedede;
        @media (max-width: 801px) {
          background-color: transparent;
        }
      }
    }
    > input[id='Uncertain'] + label {
      background: url(${images.Uncertain}) 50% 50%/110px no-repeat;
      :hover {
        background: url(${images.Uncertain}) 50% 50%/110px no-repeat #dedede;
        @media (max-width: 801px) {
          background-color: transparent;
        }
      }
    }
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  height: ${DEFAULT_SIZE.inputFieldHeight};
  border-radius: 0;
  font-size: 16px;
  background: #fff;
  padding: 0 10px;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '1px solid #787878'} !important; /* overrides initial setting*/

  ::-webkit-input-placeholder {
    text-align: center;
  }
  text-align: center;
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 0;
  /* border: ${props => props.border}; */
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;

  // p {
  //   display: none;
  // }

  input {
    width: 100%;
    max-width: ${DEFAULT_SIZE.inputFieldWidth};
    margin: auto;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  margin: 0;
  padding-left: 5px;
  /* border: 1px inset #fff; */
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 98% 50% no-repeat #fff;
  background-size: 20px;
`;

// Zip input
const StyledZip = css`
  ${inputStyle}
  /* margin-bottom: 12px;
  color: #555;
  padding: 0 20px; */

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  &.final-input-container-first_name,
  &.final-input-container-last_name {
    display: none;
  }

  input,
  select {
    margin-top: 0;
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  input {
    height: ${DEFAULT_SIZE.inputFieldHeight};
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 600;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  &#questionTitle-zipcode,
  &#questionTitle-ElectricCompany,
  &#questionTitle-email,
  &.questiontitle-first_name {
    display: none;
  }
  .questionbody-1.questionbody-FName & {
    visibility: hidden;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
`;

const Subtitle = css`
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  &.subtitle-credit_rating {
    margin-bottom: 20px;
  }
  &.subtitle-path-choice,
  &.subtitle-ElectricBill,
  &.subtitle-ElectricBillOver100,
  &.subtitle-Shade,
  &.subtitle-HasSolar,
  &.subtitle-property_type,
  &.subtitle-opt_served_military,
  &.subtitle-HasRoofReplacement {
    display: none;
  }
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 10px;
  color: #000;
  font-size: 15px;
  border-radius: 3px;
  width: 80%;
`;

const QuestionContainer = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &.question-container-FName {
    h1 {
      margin-bottom: 10px;
      grid-column: 1 / -1;
    }
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    .surveyContainerFF & {
      display: flex;
    }
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  .emailLabel {
    display: none;
  }
  .altShade & {
    &.question-Shade {
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 801px) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
  }
  .electricProviderChoice {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .providerFail {
      text-align: center;
    }
  }
  &.question-ElectricBillButtons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
`;

// Slider
const SliderBox = css`
  width: calc(100% - 30px);
`;
const StyledSlider = css`
  width: 100%;
  height: 40px;
  margin: -35px auto 0;
`;

const StyledThumb = css`
  background: #f6f6f6;
  border: 1px solid #bdc3c7;
  width: 1.2em;
  height: 1.2em;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-top: 30px;
`;

const DisplayNumber = css`
  font-size: ${({ primary }) => (primary ? '18px' : '14px')};
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0;
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SliderContainer = css`
  width: 100%;
  height: 10px; /* Don't forget to change the height of "FillContainer" as well */
  border-radius: 4px;
  border: 1px solid #bdc3c7;
  background: #fff;
  margin: 35px auto 0;
`;

const FillContainer = css`
  position: absolute;
  height: 10px;
  border-radius: 3px 0 0 3px;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
  border: 1px solid #c5c5c5;
`;

// ProgressBar
const ProgressContainer = css`
  margin: 0 auto 10px auto;
  width: 100%;
  max-width: 700px;
  height: 15px;
  background: ${DEFAULT_COLORS.progressBarBackground};
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const StatusBar = css`
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
`;

// Used for the footer progressbar.
const FooterNumberStatus = css`
  &#your-progress {
    display: none;
  }
  /* position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -52px;
  color: #fff;
  font-weight: 300;

  @media (max-width: 800px) {
    display: none;
  } */
`;

// buttons
const SubmitButton = css`
  margin: 5px 0 0;
  width: ${DEFAULT_SIZE.submitButtonWidth};
  padding: 0;
  border-radius: 0;
  height: 45px;
  background-color: ${props =>
    props.color || DEFAULT_COLORS.submitButtonBackground};
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DEFAULT_COLORS.submitButtonText};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    width: 90%;
    max-width: 300px;
    margin: 0 auto 0;
    font-size: clamp(18px, 2vw, 24px);
  }
`;

const BackButton = css`
  border: none;
  background: inherit;
  color: #676767;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100px;
  font-size: 14px;
  text-align: center;
  height: 45px;

  &:hover {
    opacity: 0.8;
  }
  &.last-page-previous-button {
    display: none;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: none;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background: ${DEFAULT_COLORS.footerBackground};
  max-width: 960px;
  width: 100%;
  margin: auto;

  p {
    margin-bottom: 0;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }
`;

const DisclosureContainer = css`
  text-align: center;
  max-width: 960px;
  margin: 3vh auto;

  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 2vh auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 1vh;

  span {
    color: ${DEFAULT_COLORS.link};
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const PersistentMessage = css`
  font-size: 12px;
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 18px;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
`;

const Text = css`
  color: #333;
  font-size: 14px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: ${props => props.primary && '0'};
`;

const FooterText = css`
  margin-top: ${props => props.primary && '30px'};
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin: 0 auto;
  width: calc(100% - 10px);
`;

const HeadlineHeader = css`
  color: ${DEFAULT_COLORS.headline};
  font-size: ${DEFAULT_FONT.headline};
  font-weight: 700;
  margin-bottom: 10px;

  &.headlineTest {
    color: #57aee1;
  }
`;

const SubHeader = css`
  font-size: ${DEFAULT_FONT.text};
  font-weight: 400;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 10px;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  font-style: italic;
  text-align: center;
  font-size: 12px;
  margin-top: 0 !important;
  &#error-message {
    margin-top: 5px !important;
  }
`;

const FinalDisclosureContainer = css`
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  min-height: 20px;
  width: calc(100% - 20px);
  max-width: 600px;
  margin: 20px auto 10px auto;
  text-align: center;

  p,
  label {
    margin-bottom: 10px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
    line-height: 1.3;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: 12px;
  padding-left: 25px;

  > input[type='checkbox'] {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin: 0;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      -webkit-appearance: auto;
      height: 15px;
      width: 15px;
      margin: 0 10px 0 -25px;
    }
  }

  > input[type='checkbox'] {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      border: 1px solid #000;
      border-radius: 3px;
      background-color: white;
      margin: 4px;
    }
    &:checked:before {
      background-color: #0075ff;
    }
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 4px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 5px;
    margin: 5px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin: 4px 0 0 !important;
  line-height: 1.2;
  font-weight: 400;
  text-align: left;
  font-style: italic;
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  width: 100%;
  margin-top: -15px;

  p {
    margin-bottom: 0;
  }
  button {
    color: #447abe;
    font-size: 13px;
    text-decoration: underline;
    margin: 0;
    text-align: right;
  }
`;

const AddressSubtext = css`
  display: none;
`;

const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

export default {
  HeaderContainer,
  HeaderImage,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderBox,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  StatusBar,
  FooterNumberStatus,
  SubmitButton,
  BackButton,
  ButtonContainer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  PersistentMessage,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
};
